const areEquals = (a, b) => {
  const aKeys = Object.keys(a).sort(sortByFirst);
  const bKeys = Object.keys(b).sort(sortByFirst);

  if (JSON.stringify(aKeys) !== JSON.stringify(bKeys)) {
    return false;
  }

  for (let index = 0; index < aKeys.length; index++) {
    if (JSON.stringify(a[aKeys[index]]) !== JSON.stringify(b[bKeys[index]])) {
      return false;
    }
  }

  return true;
};

const sortByFirst = (a, b) => (a[0] > b[0] ? 1 : -1);
export default areEquals;
