import { mapActions, mapGetters } from 'vuex';
import { ITEMS_PER_PAGE, SAVE_ITEMS_PER_PAGE } from '@/store/modules/filters/keys';

export const paginationMixin = {
  data: () => ({
    totalPages: null,
    defaultCurrentPage: 1,
    availablePageSizes: [10, 20, 50],
  }),
  computed: {
    ...mapGetters([ITEMS_PER_PAGE]),
    idView() {
      return this.$route.name;
    },
    getItemsPerPage() {
      return parseInt(this.queryParams?.itemsPerPage) || this[ITEMS_PER_PAGE](this.idView);
    },
    itemsPerPage() {
      return this.availablePageSizes.includes(this.getItemsPerPage) ? this.getItemsPerPage : this.availablePageSizes[0];
    },
    currentPage() {
      return parseInt(this.queryParams?.page) || this.defaultCurrentPage;
    },
  },
  created() {
    if (this.getItemsPerPage) {
      this.queryParams.itemsPerPage = this.getItemsPerPage;
    }
  },
  methods: {
    ...mapActions([SAVE_ITEMS_PER_PAGE]),
    saveItemsPerPage(number) {
      this[SAVE_ITEMS_PER_PAGE]({ idView: this.idView, number });
    },
    changeItemsPerPage(value) {
      this.saveItemsPerPage(value);
      // this methods is called from the index mixin
      this.onNewPageSize(value);
    },
  },
};
