/**
 * This mixin handles the url query params and is responsible to keep consistency between the queryParams var and the
 * real params on the url.
 *
 */
import areEquals from '@/utils/validation/areEquals';

export const queryParamsMixin = {
  data() {
    return {
      queryParams: {},
    };
  },
  created() {
    this.addQueryParams(this.$route.query);
  },
  methods: {
    /**
     * Force the url replacement
     */
    async queryParamsRouterReplace() {
      const params = this.queryParams;
      if (!areEquals(this.$router.currentRoute.query, params)) {
        await this.$router.replace({ ...this.$router.currentRoute, query: params }).catch(() => {});
      }
    },

    /**
     *
     * @param {Object.<string, string>} params - Query params from the url in a dictionary object
     *
     * Add queryParams to existing ones
     */
    addQueryParams(params) {
      this.$_queryParamsMixin_setQueryParams(params, false);
    },

    /**
     *
     * @param {Array<string>} params - Filter keys to remove
     *
     * Remove queryParams
     */
    removeQueryParams(params) {
      params.forEach(this.removeQueryParam);
    },

    /**
     *
     * @param {string} key Filter keys to remove
     *
     * Remove queryParam
     */
    removeQueryParam(key) {
      delete this.queryParams[key];
    },

    /**
     *
     * @param {Object.<string, string>} params - Query params from the url in a dictionary object
     *
     * Remove all params and set the new ones.
     */
    replaceQueryParams(params) {
      this.$_queryParamsMixin_setQueryParams(params);
    },

    /**
     *
     * @param {Object.<string, string>} params - Query params from the url in a dictionary object
     * @param {boolean} clear - Clear previous queryParams
     *
     * Set query params and remove the old ones if required
     */
    $_queryParamsMixin_setQueryParams(params, clear = true) {
      const paramsAsString = {};

      if (clear) {
        this.queryParams = {};
      }

      Object.entries(params).forEach(filter => {
        const [key, value] = filter;
        if (value !== null && undefined !== value) paramsAsString[key] = value.toString();
        else delete this.queryParams[key];
      });

      this.queryParams = { ...this.queryParams, ...paramsAsString };
    },
    clearQueryParams() {
      this.queryParams = {};
    },
  },
};
