import { mapActions, mapGetters } from 'vuex';
import ApiRequest from '@/utils/apiRequest';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import { paginationMixin } from '@/mixins/index/paginationMixin';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import { SAVE_SORTERS, SORTER_LIST } from '@/store/modules/filters/keys';

export const indexMixin = {
  mixins: [paginationMixin, queryParamsMixin],
  data: () => ({
    currentApi: null,
    groupBy: null,
    isLoading: false,
    resource: '',
    headers: [],
    sort: null,
    mixinFirstLoad: true,
    totalItems: 0,
    totalPages: 1,
    sectionStatus: SECTION_STATUS.OK,
    cachedItems: null,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      storedSortersFind: SORTER_LIST,
    }),
    activeClientId() {
      return this.activeClient?.id;
    },
    defaultSort() {
      return { field: undefined, order: undefined };
    },
    initialSort() {
      if (this.queryParams.sort) {
        const sortParser = /(-)?(.+)/.exec(this.queryParams.sort);
        const order = sortParser[1] ? 'desc' : 'asc';
        const field = sortParser[2];
        return { field, order };
      }
      return this.storedSortersFind(this.$route.name) || this.defaultSort;
    },
  },
  created() {
    this.loadSortParamFromURL();
    this.eventOrder(this.initialSort);
  },
  beforeDestroy() {
    this.currentApi?.refreshCancelToken();
  },
  methods: {
    ...mapActions({ saveSorters: SAVE_SORTERS }),

    async filterOnFiltersFindChanged() {
      await this.getTableItems();
    },
    getCompleteHeaderName(header) {
      return header.findValue ? `${header.value}.${header.findValue}` : header.value;
    },

    async onSortTable(sortEvent) {
      this.addQueryParams({ page: 1 });
      this.eventOrder({ field: sortEvent.key, order: sortEvent.sort });
      await this.updateSortInURL();
      await this.getTableItems();
    },

    eventOrder({ field, order }) {
      if (order) {
        this.sort = { field, order };
        this.updateSort();
      } else {
        this.resetSort();
      }
      this.saveSorters({ idView: this.$route.name, sorter: this.sort });
    },

    /**
     * @param {QueryParamsBuilder|undefined} params
     */
    setParamsToTable(params) {
      const auxParams = params || new QueryParamsBuilder(this.currentPage, this.itemsPerPage);

      // Add find filters to filters params
      const filtersToRequest = this.filtersMakeToRequest?.() || new Map();

      filtersToRequest.forEach((value, name) => {
        auxParams.addFilter(name, value);
      });

      if (this.sort && this.sort.field) {
        auxParams.addSort(this.sort.field, this.sort.order?.toUpperCase());
      } else if (typeof this.sort === 'string') {
        auxParams.sort = this.sort;
      } else if (this.defaultSort?.field) {
        auxParams.addSort(this.defaultSort.field, this.defaultSort.order);
      }
      return auxParams;
    },

    async goToPage({ page }) {
      this.addQueryParams({ page });
      await this.queryParamsRouterReplace();
      await this.getTableItems();
    },

    async onNewPageSize(value) {
      // TODO: remove when sun-pagination-page is fixed
      // page event is emitted twice. Second one, the value is a string
      if (parseInt(value) !== parseInt(this.queryParams?.itemsPerPage)) {
        this.addQueryParams({ itemsPerPage: value, page: 1 });
        await this.queryParamsRouterReplace();
        await this.getTableItems();
      }
    },

    setParamsToQueryString() {
      const query = this.filtersMakeQueryString?.() || {};

      // clear query params
      Object.entries(query).forEach(keyValue => {
        const [key] = keyValue;
        if (/sort\[.+\]/.test(key)) delete query[key];
      });

      if (query.page) {
        query.page = this.currentPage;
      }

      if (this.sort?.field) {
        query[`sort[${this.sort.field}]`] = this.sort.order;
      }

      this.addQueryParams(query);
    },

    async resetPageOnQueryParams() {
      if (this.queryParams.page) {
        this.addQueryParams({ page: 1 });
        await this.queryParamsRouterReplace();
      }
    },

    async onQuickFiltersSearch(value, property = 'name') {
      this.$set(this.filterQuick, property, value?.trim() || undefined);
      await this.resetPageOnQueryParams();
      await this.filtersUpdateFiltersOnStoreAndURL();
      await this.getTableItems();
    },

    async onActiveClientChange() {
      this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
      await this.resetPageOnQueryParams();

      await this.filtersOnActiveClientChange?.();

      this.resetCache();
      await this.getTableItems();
    },

    /**
     *
     * @param endpoint Function to query the corresponding API
     * @param {QueryParamsBuilder} params Custom params
     * @return {Array} An array filled with the items queried from the API
     */
    async getItemsFromAPI(endpoint, params = null) {
      const auxParams = params || this.setParamsToTable();
      let data = [];

      const { isCancel } = await ApiRequest(async () => {
        this.sectionStatus = SECTION_STATUS.OK;
        // TODO delete set this.activeClient.id, is a better way to make
        // wrapper on server function
        const resp = await endpoint(auxParams, this.activeClient.id);
        const meta = resp?.meta;
        data = resp?.data;

        this.totalPages = meta?.totalPages || 1;
        this.totalItems = meta?.totalResults || data?.length;
        if (data?.length) {
          this.cachedItems = data;
        } else if (this.cachedItems !== null) {
          this.sectionStatus = SECTION_STATUS.EMPTY;
        }
      }).catch(() => {
        this.sectionStatus = SECTION_STATUS.ERROR;
      });
      const cacheOrEmpty = this.cachedItems || [];
      return {
        data: data?.length ? data : cacheOrEmpty,
        isCancel,
      };
    },

    /**
     * Set sort to initial state in all columns but the one included on the sort param
     * @param {string | undefined}  headerName original header name, which can be different from the sort one
     */
    updateHeadersSortState(headerName) {
      const name = headerName || this.sort?.field;
      this.headers.forEach(header => {
        if (header.value === name) {
          header.sort = this.sort?.order?.toLowerCase() || true;
        } else if (header.sort) {
          header.sort = true;
        }
      });
    },
    resetCache() {
      this.cachedItems = null;
    },
    deleteFromCacheById(id) {
      const index = this.cachedItems.findIndex(item => item.id === id);
      this.cachedItems.splice(index, 1);
    },

    resetSort() {
      this.sort = undefined;
      this.resetHeadersSortState();
    },

    resetHeadersSortState() {
      this.headers.forEach(header => {
        if (header.sort) {
          header.sort = true;
        }
      });
    },

    /**
     * Set sort to initial state in all columns but the one included on the sort param and update the URL
     * @param {string | undefined} headerName original header name, which can be different from the sort one
     */
    async updateSortInURL(headerName = undefined) {
      this.updateSort(headerName);
      await this.queryParamsRouterReplace();
    },

    /**
     * Set sort to initial state in all columns but the one included on the sort param
     * @param {string | undefined} headerName original header name, which can be different from the sort one
     */
    updateSort(headerName = undefined) {
      this.updateHeadersSortState(headerName);
      if (this.sort?.field) {
        this.addQueryParams({ sort: QueryParamsBuilder.formatSortParam(this.sort) });
      } else {
        this.removeQueryParam('sort');
      }
    },

    loadSortParam(sortString) {
      const compoundSort = sortString?.split('-');
      const field = compoundSort?.pop();
      const isHeaderSortable = this.headers.find(h => h.value === field)?.sort;

      if (field && isHeaderSortable) {
        // The field is always the last array element, so we use pop to get it
        const order = compoundSort.length > 0 ? 'DESC' : 'ASC';

        this.sort = { field, order };
        this.updateSort();
      }
    },
    loadSortParamFromURL() {
      this.loadSortParam(this.queryParams?.sort);
    },
  },
};
